<template>
  <ion-page>
    <ion-content class="maxah-content">
      <div class="parallax-wrapper">
        <div class="maxah parallax-content relative">
          <img class="relative" src="assets/images/ab-cover.jpeg" />
          <div class="maxah__title">
            <div class="content">
              <h1 class="h1 ion-text-left">
                <span class="main">
                  <ion-text color="white" class="elements">
                    <span>Fueled by </span>
                  </ion-text>
                  <ion-text color="primary" class="elements">
                    <span>Passion,</span>
                  </ion-text>
                  <br />
                  <ion-text color="white" class="elements">
                    <span> Focused on </span>
                  </ion-text>
                  <ion-text color="primary" class="elements">
                    <span>Inspiration </span>
                  </ion-text>
                  <ion-text color="white" class="elements">
                    <span> &amp; </span>
                  </ion-text>
                  <br />
                  <ion-text color="white" class="elements">
                    <span> Flourishing through </span>
                  </ion-text>
                  <ion-text color="primary" class="elements">
                    <span>Diligence</span>
                  </ion-text>
                </span>
              </h1>
            </div>
          </div>
        </div>
        <div class="main-content">
          <div class="maxah__title">
            <div class="content">
              <h1 class="h1 ion-text-center">
                <span class="main">
                  <ion-text color="white" class="elements">
                    <span>Who We </span>
                  </ion-text>
                  <ion-text color="primary" class="elements">
                    <span>Are</span>
                  </ion-text>
                </span>
              </h1>
              <p class="ion-text-center description who-we-are">
                We are a software development company that provides cutting edge
                engineering solutions, helping customers untangle complex issues
                that always emerge during their digital evolution journey.
              </p>
            </div>
          </div>
          <div class="maxah__title">
            <div class="content">
              <h1 v-if="activeSegment === 'services'" class="h1 ion-text-center">
                <span class="main">
                  <ion-text color="white" class="elements">
                    <span>What We </span>
                  </ion-text>
                  <ion-text color="primary" class="elements">
                    <span>Offer</span>
                  </ion-text>
                </span>
              </h1>
              <h1 v-if="activeSegment === 'technologies'" class="h1 ion-text-center">
                <span class="main">
                  <ion-text color="white" class="elements">
                    <span>What We </span>
                  </ion-text>
                  <ion-text color="primary" class="elements">
                    <span>Use</span>
                  </ion-text>
                </span>
              </h1>
              <h1 v-if="activeSegment === 'industries'" class="h1 ion-text-center">
                <span class="main">
                  <ion-text color="white" class="elements">
                    <span>Whom Do We </span>
                  </ion-text>
                  <ion-text color="primary" class="elements">
                    <span>Serve</span>
                  </ion-text>
                </span>
              </h1>
              <ion-segment
                scrollable
                :value="activeSegment"
                @ionChange="segmentChanged($event)"
              >
                <ion-segment-button value="services"
                  >Services</ion-segment-button
                >
                <ion-segment-button value="technologies"
                  >Technologies</ion-segment-button
                >
                <ion-segment-button value="industries"
                  >Industries</ion-segment-button
                >
              </ion-segment>
              <div v-if="activeSegment === 'services'">
                <ion-grid>
                  <ion-row>
                    <ion-col
                      size-xs="12"
                      size-sm="12"
                      size-xl="4"
                      size-lg="4"
                      size-md="4"
                      v-for="ind of allServices"
                      :key="ind.name"
                    >
                      <ion-card @click="
                      router.push(
                        ind.link + getHash(ind.name)
                      )
                    ">
                        <ion-item>
                          <ion-thumbnail slot="start">
                            <img :src="ind.img" />
                          </ion-thumbnail>
                          <ion-label>{{ ind.name }}</ion-label>
                        </ion-item>

                        <ion-card-content>
                          {{ ind.description }}
                        </ion-card-content>
                      </ion-card>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </div>
              <div v-if="activeSegment === 'technologies'">
                <ion-grid>
                  <ion-row>
                    <ion-col
                      size-xs="6"
                      size-sm="6"
                      size-xl="3"
                      size-lg="3"
                      size-md="3"
                      v-for="img in technologies"
                      :key="img.name"
                      @click="openTechLinks(img.link)"
                    >
                      <ion-card class="tech">
                        <img class="ion-card-image-tech" :src="img.img" />
                        <ion-card-header>
                          <ion-card-subtitle></ion-card-subtitle>
                          <ion-card-title>{{ img.name }}</ion-card-title>
                        </ion-card-header>
                      </ion-card>
                    </ion-col></ion-row
                  ></ion-grid
                >
              </div>
              <div v-if="activeSegment === 'industries'">
                <ion-grid>
                  <ion-row>
                    <ion-col
                      size-xs="12"
                      size-sm="12"
                      size-xl="4"
                      size-lg="4"
                      size-md="4"
                      v-for="ind of industries"
                      :key="ind.name"
                    >
                      <ion-card @click="
                      router.push(
                        ind.link + getHash(ind.name)
                      )
                    ">
                        <ion-item>
                          <ion-thumbnail slot="start">
                            <img :src="ind.img" />
                          </ion-thumbnail>
                          <ion-label>{{ ind.name }}</ion-label>
                        </ion-item>

                        <ion-card-content>
                          {{ ind.description }}
                        </ion-card-content>
                      </ion-card>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </div>
            </div>
          </div>
          <div class="maxah-flex">
            <div class="maxah-promo"></div>
            <!-- <div class="maxah-cform">
              <form
                id="app"
                @submit="checkForm"
                action="https://vuejs.org/"
                method="post"
                novalidate
              >
                <ion-item>
                  <ion-label position="floating">Name</ion-label>
                  <ion-input
                    type="text"
                    rules="'username|required'"
                    data-vv-as="username"
                    @input="formData.username = $event.target.value"
                    :value="formData.username"
                    name="username"
                    spellcheck="false"
                    autocapitalize="off"
                  ></ion-input>
                </ion-item>
                <ion-item>
                  <ion-label position="floating">Email</ion-label>
                  <ion-input
                    type="email"
                    rules="'email|required'"
                    data-vv-as="email"
                    @input="formData.email = $event.target.value"
                    :value="formData.email"
                    name="email"
                    spellcheck="false"
                    autocapitalize="off"
                  ></ion-input>
                </ion-item>
                <ion-item>
                  <ion-label position="floating">Message</ion-label>
                  <ion-textarea
                    rules="'message|required'"
                    data-vv-as="message"
                    @input="formData.message = $event.target.value"
                    :value="formData.message"
                    name="message"
                    spellcheck="false"
                    autocapitalize="off"
                  ></ion-textarea>
                </ion-item>
                <ion-button type="submit" expland="block">Submit</ion-button>
              </form>
            </div> -->
          </div>
        </div>
      </div>
      <MaxahDetailedFooter></MaxahDetailedFooter>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  IonContent,
  IonPage,
  IonItem,
  // IonInput,
  IonLabel,
  // IonTextarea,
  // IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonCardHeader,
  IonCardSubtitle,
  IonText,
  IonSegment,
  IonSegmentButton,
  IonThumbnail,
} from "@ionic/vue";
import { TECHNOLOGIES, SERVICES, INDUSTRIES } from "@/data/data.static";
import { useRouter } from 'vue-router';
import MaxahDetailedFooter from '@/components/DetailedFooter.vue';

export default {
  name: "AboutUs",
  components: {
    IonContent,
    IonPage,
    IonItem,
    // IonInput,
    IonLabel,
    // IonTextarea,
    // IonButton,
    IonGrid,
    IonRow,
    IonCol,
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCardHeader,
    IonCardSubtitle,
    IonText,
    IonSegment,
    IonSegmentButton,
    IonThumbnail,
    MaxahDetailedFooter
  },
  setup() {
    const formData = {
      message: "",
      username: "",
      email: "",
    };
    const onSubmit = () => {
      // bb
    };
    return {
      formData,
      onSubmit,
    };
  },
  data() {
    // eslint-disable-next-line no-var
    var activeSegment = "services";
    const router = useRouter();

    const technologies = TECHNOLOGIES;
    const industries = INDUSTRIES;
    const allServices = SERVICES;
    return {
      router,
      activeSegment,
      technologies,
      industries,
      allServices,
    };
  },
  methods: {
    segmentChanged(ev: any) {
      this.activeSegment = ev.detail.value;
    },
    openTechLinks(link: string) {
      window.open(link, "_blank");
    },
    getHash(name) {
      return "#" + name.replaceAll(/[^a-zA-Z ]/g, "").replaceAll(/\s/g,'').toLowerCase();
    }
  },
};
</script>

<style scoped>
ion-card {
  height: 100%;
}
</style>
