
import {
  IonContent,
  IonPage,
  IonItem,
  // IonInput,
  IonLabel,
  // IonTextarea,
  // IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonCardHeader,
  IonCardSubtitle,
  IonText,
  IonSegment,
  IonSegmentButton,
  IonThumbnail,
} from "@ionic/vue";
import { TECHNOLOGIES, SERVICES, INDUSTRIES } from "@/data/data.static";
import { useRouter } from 'vue-router';
import MaxahDetailedFooter from '@/components/DetailedFooter.vue';

export default {
  name: "AboutUs",
  components: {
    IonContent,
    IonPage,
    IonItem,
    // IonInput,
    IonLabel,
    // IonTextarea,
    // IonButton,
    IonGrid,
    IonRow,
    IonCol,
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCardHeader,
    IonCardSubtitle,
    IonText,
    IonSegment,
    IonSegmentButton,
    IonThumbnail,
    MaxahDetailedFooter
  },
  setup() {
    const formData = {
      message: "",
      username: "",
      email: "",
    };
    const onSubmit = () => {
      // bb
    };
    return {
      formData,
      onSubmit,
    };
  },
  data() {
    // eslint-disable-next-line no-var
    var activeSegment = "services";
    const router = useRouter();

    const technologies = TECHNOLOGIES;
    const industries = INDUSTRIES;
    const allServices = SERVICES;
    return {
      router,
      activeSegment,
      technologies,
      industries,
      allServices,
    };
  },
  methods: {
    segmentChanged(ev: any) {
      this.activeSegment = ev.detail.value;
    },
    openTechLinks(link: string) {
      window.open(link, "_blank");
    },
    getHash(name) {
      return "#" + name.replaceAll(/[^a-zA-Z ]/g, "").replaceAll(/\s/g,'').toLowerCase();
    }
  },
};
